import React from "react";
import { Link } from "react-router-dom";
import Publish from "../../assets/Publish/Publish.png";
import Arrow from "../../assets/Publish/arrow.svg";
import Passion from "../../assets/Publish/Ebook.svg";
import Bridge from "../../assets/Publish/phyisical.svg";
import StayFearless from "../../assets/Publish/Expert.svg";
import "../../Component/FinalLanding/FinalLanding.css";
import Canva from "../../assets/Publish/Canva.png";
import CanvaLogo from "../../assets/Publish/Canva-logo.png";
import LeftArow from "../../assets/Publish/left-arrow.png";
import { useNavigate, useParams } from "react-router-dom";
const FinalLandings = () => {
  const navigate = useNavigate();
  const { slug } = useParams();
  const saveSlug = localStorage?.getItem("slug");
  return (
    // <div className="PublishContainer">
    <div className="cover-rightContainer">
      <div
        className="ArrowContainer"
        onClick={() => navigate(`/backCoverPage/${slug}`)}
      >
        <img className="ArrowImg" src={Arrow} alt="" />
        <p className="back">Back</p>
      </div>
      <div className="HeadContainer">
        <h1 className="cover-publishHead4">Great!</h1>
      </div>
      <div className="back-imageConatiner">
        <div className="backimageBox">
          <div
            className="back-cover-shadow"
            onClick={() => navigate(`/bookPreview/${slug}`)}
            style={{ border: "2px solid green" }}
          >
            <img className="back-cover-canvaImage " src={StayFearless} alt="" />
          </div>
          <h1 className="E-book">E-Book</h1>
          <p className="E-pargraph">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit.{" "}
          </p>
        </div>
        <div className="backimageBox">
          <div className="back-cover-shadow">
            <img className="back-cover-canvaImage " src={Bridge} alt="" />
          </div>
          <h1 className="E-book">Physical Book</h1>
          <p className="E-pargraph">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit.{" "}
          </p>
        </div>
        <div className="backimageBox">
          <div className="back-cover-shadow">
            <img className="back-cover-canvaImage " src={Passion} alt="" />
          </div>
          <h1 className="E-book">Expert</h1>
          <p className="E-pargraph">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit.{" "}
          </p>
        </div>
      </div>
    </div>
    // </div>
  );
};

export default FinalLandings;
