import React, { useState } from "react";
import Editors from "../../Component/Editor/Editors";
import EditorsModal from "../../Component/Editor/EditorsModal";
import { ToastContainer, toast } from "react-toastify";
import { createBookChapter, updateBookChapter } from "../../Api/Authentication";
import { useNavigate, useParams } from "react-router-dom";

const EditorsPages = () => {
  const { slug } = useParams();
  const navigate = useNavigate();
  const [chapterTitle, setChapterTitle] = useState("");
  const [content, setContent] = useState("");
  const [chapterData, setChapterData] = useState();
  const [isRefresh, setIsRefresh] = useState(false);
  const handleAddandSave = () => {
    const formData = new FormData();
    formData.append("title", chapterTitle);
    formData.append("content", content);
    if (!localStorage.getItem("chapter_id")) {
      createBookChapter(slug, formData)
        .then((res) => {
          if (res?.status === 200) {
            setChapterData(res?.data?.data?.slug);
            setIsRefresh(!isRefresh);
            navigate(`/bookInfo/${slug}`);
            toast("Saved");
          }
        })
        .catch((e) =>
          toast(e.response.data.msg?.title[0], {
            closeOnClick: false,
            closeButton: false,
            type: toast.TYPE.ERROR,
            autoClose: 500,
            position: toast.POSITION.BOTTOM_CENTER,
            hideProgressBar: false,
            className: "toaster",
          })
        );
    } else {
      updateBookChapter(slug, localStorage.getItem("chapter_id"), formData)
        .then((res) => {
          if (res?.status === 200) {
            setChapterData(res?.data?.data?.slug);
            localStorage.removeItem("chapter_id");
            setIsRefresh(!isRefresh);
            toast.success("Updated", {
              hideProgressBar: false,
              className: "sucesstoaster",
            });
          }
        })
        .catch((e) =>
          toast(e.response.data.msg.title[0], {
            closeOnClick: false,
            closeButton: false,
            type: toast.TYPE.ERROR,
            autoClose: 500,
            position: toast.POSITION.BOTTOM_CENTER,
            hideProgressBar: false,
            className: "toaster",
          })
        );
    }
  };

  return (
    <div>
      <Editors
        handleAddandSave={handleAddandSave}
        setContent={setContent}
        content={content}
        setChapterTitle={setChapterTitle}
        chapterTitle={chapterTitle}
        isRefresh={isRefresh}
      />
      <EditorsModal
        setContent={setContent}
        content={content}
        setChapterTitle={setChapterTitle}
        chapterTitle={chapterTitle}
      />
    </div>
  );
};

export default EditorsPages;
