import React, { useEffect, useState } from "react";
import Home from "./Pages/Home/Homes.jsx";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import LoginPage from "./Pages/Login/LoginPage.jsx";
import "./styles/globals.scss";
import PublishPage from "./Pages/PublishPage/PublishPages.jsx";

import PublishPages4 from "./Pages/PublishPage4/PublishPages4.jsx";
import BackCover from "./Pages/BackCover/BackCover.jsx";
import BookInfo from "./Pages/BookInfo/BookInfo.jsx";
import EditorsPages from "./Pages/EditorPage/EditorsPages.jsx";
import FinalPage from "./Pages/FinalLandingPage/FinalPage.jsx";
import Sign from "./Pages/SignUp/Sign.jsx";

import ForgetPaswordStep1 from "./Pages/ForgetPasword/ForgetPaswordStep1.jsx";
import ForgetPaswordStep2 from "./Pages/ForgetPaswordStep2/ForgetPaswordStep2.jsx";
import ForgetPaswordStep3 from "./Pages/ForgetPaswordFinalsteps/ForgetPaswordStep3.jsx";
import ChangePassword from "./Pages/ChangePassword/ChangePassword.jsx";
import ReadSection from "./Pages/Read/ReadSection.js";
import { useContext } from "react";
import { AuthContext } from "./Utills/Contexts/AuthContext.jsx";
import BookPreview from "./Pages/Preview/BookPreview.js";
import Profile from "./Pages/Profile/Profile.js";
import ShopPage from "./Pages/Shop/ShopPage.js";
import Services from "./Pages/Services/Services.js";
import "./firebase-messaging-sw.js";
import { requestPermission, onMessageListener } from "./firebase.js";
import { browserNotification } from "./Api/Authentication.js";
import { Toaster, toast } from "react-hot-toast";
import { ReadDetails } from "./Pages/Read/ReadDetails.js";
const App = () => {
  const { isAuthed } = useContext(AuthContext);
  const auth = localStorage.getItem("writroToken");
  const [notification, setNotification] = useState({
    title: "",
    body: "",
  });
  const ProtectedComponent = ({ children }) => {
    if (localStorage?.getItem("writroToken")) {
      return <Navigate to="/Editors" />;
    }
    return <>{children}</>;
  };
  const ProtectedComponent2 = ({ children }) => {
    if (!localStorage?.getItem("writroToken")) {
      return <Navigate to="/login" />;
    }
    return <>{children}</>;
  };

  useEffect(() => {
    requestPermission().then((res) => {
      console.log("res", res);
      const data = {
        token: res,
        platform: "writro",
      };
      browserNotification(data).then((res) => {});
    });
  }, []);

  return (
    <div>
      <Toaster />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/login" element={<LoginPage />}></Route>
          {/* <Route path="/signUp" element={<Otps />}></Route> */}
          <Route
            path="/signUp"
            element={
              <ProtectedComponent>
                <Sign />
              </ProtectedComponent>
            }
          ></Route>
          <Route
            path="/forgotPaswordStep1"
            element={<ForgetPaswordStep1 />}
          ></Route>
          <Route
            path="/forgotPaswordStep2"
            element={<ForgetPaswordStep2 />}
          ></Route>
          <Route
            path="/forgotPaswordFinalStep"
            element={<ForgetPaswordStep3 />}
          ></Route>

          <Route
            path="/publish"
            element={
              <ProtectedComponent2>
                <PublishPage />
              </ProtectedComponent2>
            }
          ></Route>
          <Route
            path="/read"
            element={
              <ProtectedComponent2>
                <ReadSection />
              </ProtectedComponent2>
            }
          ></Route>
          <Route
            path="/read/:slug"
            element={
              <ProtectedComponent2>
                <ReadDetails />
              </ProtectedComponent2>
            }
          ></Route>
          <Route path="/coverPage/:slug" element={<PublishPages4 />}></Route>
          <Route path="/backCoverPage/:slug" element={<BackCover />}></Route>
          <Route path="/bookInfo/:slug" element={<BookInfo />}></Route>
          <Route path="/editors/:slug" element={<EditorsPages />}></Route>
          <Route path="/finalLanding/:slug" element={<FinalPage />}></Route>
          <Route path="/bookPreview/:slug" element={<BookPreview />}></Route>
          <Route path="/profile" element={<Profile />}></Route>
          <Route path="/changePassword" element={<ChangePassword />}></Route>
          <Route
            path="/shop"
            element={
              <ProtectedComponent2>
                <ShopPage />
              </ProtectedComponent2>
            }
          ></Route>
          <Route
            path="/services"
            element={
              <ProtectedComponent2>
                <Services />
              </ProtectedComponent2>
            }
          ></Route>

          {/* <Route path="*" element={<Navigate to="/Login" replace />} /> */}
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default App;
