import React from "react";
import { Link } from "react-router-dom";
import HomePage from "../../images/homePage/homePageimage.png";
// import WritroWriter from "../../images/homePage/Writer.svg";
import MobileApp from "../../images/homePage/MobileApp.png";
import Mobile from "../../assets/homepage/Mobiles.svg";
import WritroWriter from "../../assets/homepage/Writer.svg";
import WritroReader from "../../assets/homepage/Mockup.svg";
import WriroSocial from "../../assets/homepage/WritroImage.png";
export const HomeContent = () => {
  return (
    <div className="home-page">
      <section className="home-banner">
        <div className="circle-left"></div>
        <div className="banner-section">
          <h1>Want to publish your book?</h1>
          <h1>
            Write on the go with{" "}
            <span className="important_heading">WRITRO</span>,
          </h1>
          <div className="banner-buttons-section">
            <div className="button">
              <Link to="/publish">Start Publishing</Link>
            </div>
            <div className="button">
              <Link to="/read">Start Reading</Link>
            </div>
          </div>
        </div>
        <div className="circle-right"></div>
      </section>
      <section className="home-image-section">
        <img src={HomePage} height="30%" width="30%" alt="" />
      </section>
      <div className="gap"></div>
      <section className="witro-witer-section">
        <div className="text-div">
          <h1>Writro Writer</h1>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fermentum
            porttitor duis nec leo ac. Natoque et sapien quisque mauris tempor,
            sapien accumsan. Nibh metus scelerisque dolor rhoncus est amet
            auctor.
          </p>
          <div className="button">
            <Link to="#">Let’s Write</Link>
          </div>
        </div>
        <img
          src={WritroWriter}
          width="40%"
          height="30%"
          alt=""
          className="WritroHomeImages"
        />
      </section>
      <div className="gap"></div>
      <section
        className="witro-witer-section"
        style={{ flexDirection: "row-reverse" }}
      >
        <div className="text-div-2">
          <h1>Writro Reader</h1>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fermentum
            porttitor duis nec leo ac. Natoque et sapien quisque mauris tempor,
            sapien accumsan. Nibh metus scelerisque dolor rhoncus est amet
            auctor. Tincidunt libero aliquam sapien nibh lacus
          </p>
          <div className="button" style={{ float: "right" }}>
            <Link to="#">Let’s Read</Link>
          </div>
        </div>
        <img
          src={WritroReader}
          width="30%"
          height="52%"
          alt=""
          className="WritroHomeImages"
        />
      </section>
      <div className="gap"></div>
      <div className="playStore-availbilty">
        <img className="Writro-Footer" src={Mobile} alt="" />
      </div>
    </div>
  );
};
