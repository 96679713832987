import React, { useState } from "react";
import "../../Component/Publish/Publish.css";
import { useNavigate } from "react-router-dom";
import Publish from "../../assets/Publish/bro.svg";
import Arrow from "../../assets/Publish/arrow.svg";
import { postWriteBook } from "../../Api/Authentication";
import { ToastContainer, toast } from "react-toastify";
const Publishs = () => {
  const navigate = useNavigate();
  const [title, setTitle] = useState("");
  const [author_name, setAuthor_name] = useState(
    localStorage.getItem("author_name")
  );
  const handleSubmit = () => {
    const userData = {
      language: 1,
      title,
      author_name,
    };
    postWriteBook(userData)
      .then((res) => {
        if (res?.status === 200) {
          localStorage.setItem("slug", res?.data?.data?.slug);
          navigate(`/coverPage/${res?.data?.data?.slug}`);
        }
      })
      .catch((e) =>
        toast("Login First", {
          closeOnClick: false,
          closeButton: false,
          type: toast.TYPE.ERROR,
          autoClose: 500,
          position: toast.POSITION.BOTTOM_CENTER,
          hideProgressBar: false,
          className: "toaster",
        })
      );
  };
  return (
    <div className="PublishContainer">
      <div className="rightContainer">
        <div className="ArrowContainer">
          <img className="ArrowImg" src={Arrow} alt="" />
          <p className="back" onClick={() => navigate("/")}>
            Back to account
          </p>
        </div>
        <div>
          <h1 className="publishHead">It all starts with a habit</h1>
          <p className="publishPara">Writing a book is not that difficult...</p>
          <div className="form-control">
            <label for="password">Book Title</label>
            <input
              type="text"
              name="title"
              id="title"
              placeholder="Enter your book’s name"
              required
              onChange={(e) => setTitle(e.target.value)}
              style={{ background: "#fbfbfb" }}
            />
          </div>
          <div className="form-control" style={{ marginTop: "5%" }}>
            <label for="password">Author’s Name</label>
            <input
              type="text"
              name="author_name"
              id="author_name"
              placeholder="Enter your name"
              required
              value={author_name}
              onChange={(e) => setAuthor_name(e.target.value)}
              style={{ background: "#fbfbfb" }}
            />
          </div>
          <button
            type="submit"
            className="saveDetails"
            // onClick={() => navigate(`/coverPage`)}
            onClick={handleSubmit}
          >
            Save the details
            <ToastContainer />
          </button>
        </div>
      </div>
      <div className="leftContainer">
        <img className="publishUserImage" src={Publish} alt="" />
      </div>
    </div>
  );
};

export default Publishs;
