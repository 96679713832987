import React, { useEffect, useState } from "react";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
console.log("run");
const firebaseConfig = {
  apiKey: "AIzaSyCkHaO1H7Zoje5z9KJwdlA0rA1gIoWCJjs",
  authDomain: "kavishala-web-new.firebaseapp.com",
  projectId: "kavishala-web-new",
  storageBucket: "kavishala-web-new.appspot.com",
  messagingSenderId: "768650156474",
  appId: "1:768650156474:web:26aedc22e80588aafde583",
  measurementId: "G-LXZBBC19TR",
};

export const requestPermission = async () => {
  return new Promise((resolve, reject) => {
    if (typeof Notification !== "undefined") {
      Notification.requestPermission().then((permissions) => {
        if (permissions === "granted") {
          console.log("permission granted");
          const app = initializeApp(firebaseConfig);
          const messaging = getMessaging(app);
          getToken(messaging, {
            vapidKey:
              "BMMYTErWEZIaaarPkzGQS-Li8FOf-Riu_Q6MT07dUDEDvoYx7eXIBIE0bnHanJx6l-SXgdHy6dZ5XWEJLHNTC3M",
          }).then((currentToken) => {
            if (currentToken) {
              console.log("currenttoken", currentToken);
              resolve(currentToken);
            } else {
              console.log("failed to gernate token");
            }
          });
        } else {
          console.log("you have't permisiion");
        }
      });
    }
  });
};

// requestPermission();
