import React, { useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../../images/navbar/navbarlogo.png";
import { useParams } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
const Navbar = () => {
  const navigate = useNavigate();
  const parmas = useParams();
  const location = useLocation();
  location?.search.split("=");
  console.log("parmas", parmas);
  const kavishala_data = location?.search.split(/[=&]/);
  console.log("location", kavishala_data);

  if (kavishala_data.length > 1) {
    localStorage.setItem("writroToken", kavishala_data[1]);

    navigate(`/${kavishala_data[3]}`);
  }
  const [selected, setSelected] = useState("Profile");
  localStorage.setItem("route", window.location.pathname);
  const logOut = () => {
    localStorage.clear();
    window.location.href = "/";
  };

  const handleChange = (value) => {
    if (value === "Log out") {
      logOut();
      setSelected(value);
      return;
    } else if (value === "Change Password") {
      setSelected(value);
      navigate(`/changePassword`);

      return;
    } else if (value === "Profile") {
      setSelected(value);
      navigate(`/profile`);

      return;
    } else {
      return;
    }
  };
  const options = ["Log out", "Change Password", "Profile"];
  return (
    <div className="navbar">
      <Link to="/">
        <img src={Logo} className="nav-logo" alt="" />
      </Link>
      <ul className="center-menu">
        <li className="list-item">
          <Link to="/publish">Publish</Link>
        </li>
        <li className="list-item">
          <Link to="/services">Services</Link>
        </li>
        <li className="list-item">
          <Link to="/read">Read</Link>
        </li>
        <li className="list-item">
          <Link to="/shop">Shop</Link>
        </li>
      </ul>
      {localStorage.getItem("route") === "/publish" ? (
        <div className="nav-login-section">
          {/* <div className="nav-signup">
            <Link to="#">Save and Continue</Link>
          </div> */}
          {localStorage?.getItem("writroToken") ? (
            <Dropdown
              options={options}
              seleted={"Profile"}
              onChange={(e) => handleChange(e.value)}
              value={selected}
              className="drop-downs"
            />
          ) : (
            <div className="nav-signup">
              <Link to="/login">Login</Link>
            </div>
          )}
        </div>
      ) : (
        <div>
          {localStorage.getItem("writroToken") ? (
            <Dropdown
              options={options}
              seleted={"Profile"}
              onChange={(e) => handleChange(e.value)}
              value={selected}
              className="drop-downs"
            />
          ) : (
            <div className="nav-login-section">
              <div className="nav-login">
                <Link to="/login">Login</Link>
              </div>
              <div className="nav-signup">
                <Link to="/signUp">Signup</Link>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Navbar;
