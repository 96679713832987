import axios from "axios";
const baseURL = "https://admin.writro.com/";

const login = (data) => {
  const link = "authentication/login/";
  const url = `${baseURL}${link}`;

  try {
    const response = axios.post(url, data).then((res) => {
      return res;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const Sociallogin = (data) => {
  const link = "authentication/users/sociallogin/";
  const url = `${baseURL}${link}`;

  try {
    const response = axios.post(url, data).then((res) => {
      return res;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const Varification = (data) => {
  const link = `authentication/check_mobile_number/?verify_for=s&phone_number=91${data?.phone_number}`;
  const url = `${baseURL}${link}`;
  try {
    const response = axios.get(url).then((res) => {
      return res;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const Sign = (data) => {
  const link = "authentication/signup_using_mobile/";
  const url = `${baseURL}${link}`;

  try {
    const response = axios.post(url, data).then((res) => {
      return res;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const changePassword = (data) => {
  const link = "authentication/change_password/";
  const url = `${baseURL}${link}`;
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("writroToken")}`,
    },
  };
  try {
    const response = axios.post(url, data, config).then((res) => {
      return res;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const ForgetStep1 = (data) => {
  const link = `authentication/forget_password_step_1/?email=${data?.email}&phone_number=${data?.phone_number}`;
  const url = `${baseURL}${link}`;
  try {
    const response = axios.get(url).then((res) => {
      return res;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const ForgetStep2 = (data) => {
  const link = `authentication/forget_password_step_2/?email=${data?.email}&phone_number=${data?.phone_number}&email_otp=${data?.email_otp}&phone_number_otp=${data?.phone_number_otp}`;
  const url = `${baseURL}${link}`;
  try {
    const response = axios.get(url).then((res) => {
      return res;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const finalStep = (data) => {
  const link = "authentication/forget_password_final_step/";
  const url = `${baseURL}${link}`;

  try {
    const response = axios.post(url, data).then((res) => {
      return res;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const createBookChapter = (slug, data) => {
  const link = `bookModule/my_books/${slug}/`;
  const url = `${baseURL}${link}`;
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("writroToken")}`,
    },
  };
  try {
    const response = axios.post(url, data, config).then((res) => {
      return res;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const getProfile = (slug) => {
  const link = `authentication/user_profile/`;
  const url = `${baseURL}${link}`;
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("writroToken")}`,
    },
  };
  try {
    const response = axios.get(url, config).then((res) => {
      return res;
    });
    return response;
  } catch (error) {
    return error;
  }
};

const getAllBookChapter = (slug) => {
  const link = `bookModule/my_books/${slug}/`;
  const url = `${baseURL}${link}`;
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("writroToken")}`,
    },
  };
  try {
    const response = axios.get(url, config).then((res) => {
      return res;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const getPreview = (slug) => {
  const link = `bookModule/previewbook/${slug}/`;
  const url = `${baseURL}${link}`;
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("writroToken")}`,
    },
  };
  try {
    const response = axios.get(url, config).then((res) => {
      return res;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const publish = (slug, data) => {
  const link = `reader/createepubbook/${slug}/`;
  const url = `${baseURL}${link}`;
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("writroToken")}`,
    },
  };
  try {
    const response = axios.post(url, data, config).then((res) => {
      return res;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const getBookChapter = (slug, chapter_slug) => {
  const link = `bookModule/chapters/${slug}/${chapter_slug}/`;
  const url = `${baseURL}${link}`;
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("writroToken")}`,
    },
  };
  try {
    const response = axios.get(url, config).then((res) => {
      return res;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const updateBookChapter = (slug, chapter_slug, data) => {
  const link = `bookModule/chapters/${slug}/${chapter_slug}/`;
  const url = `${baseURL}${link}`;
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("writroToken")}`,
    },
  };
  try {
    const response = axios.put(url, data, config).then((res) => {
      return res;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const deleteBookChapter = (slug, chapter_slug) => {
  const link = `bookModule/chapters/${slug}/${chapter_slug}/`;
  const url = `${baseURL}${link}`;
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("writroToken")}`,
    },
  };
  try {
    const response = axios.delete(url, config).then((res) => {
      return res;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const writeBook = () => {
  const link = "bookModule/my_books/";
  const url = `${baseURL}${link}`;
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("writroToken")}`,
    },
  };
  try {
    const response = axios.get(url, config).then((res) => {
      return res;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const bookDetailsGet = (slug) => {
  const link = `bookModule/getandupdate_book/${slug}/`;
  const url = `${baseURL}${link}`;
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("writroToken")}`,
    },
  };
  try {
    const response = axios.get(url, config).then((res) => {
      return res;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const bookDetailsUpdate = (slug, data) => {
  const link = `bookModule/getandupdate_book/${slug}/`;
  const url = `${baseURL}${link}`;
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("writroToken")}`,
    },
  };
  try {
    const response = axios.put(url, data, config).then((res) => {
      return res;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const postWriteBook = (data) => {
  const link = "bookModule/my_books/";
  const url = `${baseURL}${link}`;
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("writroToken")}`,
    },
  };
  try {
    const response = axios.post(url, data, config).then((res) => {
      return res;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const putWriteBook = (data) => {
  const link = "bookModule/my_books/";
  const url = `${baseURL}${link}`;
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("writroToken")}`,
    },
  };
  try {
    const response = axios.post(url, data, config).then((res) => {
      return res;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const getAllBookDetails = (slug) => {
  const link = `bookModule/book/${slug}/`;
  const url = `${baseURL}${link}`;
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("writroToken")}`,
    },
  };
  try {
    const response = axios.get(url, config).then((res) => {
      return res;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const getAllbooks = (slug) => {
  const link = `reader/getallepubbook/`;
  const url = `${baseURL}${link}`;
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("writroToken")}`,
    },
  };
  try {
    const response = axios.get(url, config).then((res) => {
      return res;
    });
    return response;
  } catch (error) {
    return error;
  }
};
// const publicDetails = () => {
//   const link = "bookModule/book_details/prabhat-the-rise-of-selfish-man/";
//   const url = `${baseURL}${link}`;
//   const config = {
//     headers: {
//       Authorization: `Bearer ${localStorage.getItem("writroToken")}`,
//     },
//   };
//   try {
//     const response = axios.get(url, config).then((res) => {
//       return res;
//     });
//     return response;
//   } catch (error) {
//     return error;
//   }
// };
const userCreatePayment = (data) => {
  const link = `payment/createpayment/`;
  const url = `${baseURL}${link}`;
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("writroToken")}`,
    },
  };
  try {
    const response = axios.post(url, data, config).then((res) => {
      return res;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const paymentFinalStep = (data) => {
  const link = `payment/verifypayment/`;
  const url = `${baseURL}${link}`;
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("writroToken")}`,
    },
  };
  try {
    const response = axios.post(url, data).then((res) => {
      return res;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const bookIsPaid = (slug, data) => {
  const link = `bookModule/getandupdate_book/${slug}/`;
  const url = `${baseURL}${link}`;
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("writroToken")}`,
    },
  };
  try {
    const response = axios.post(url, data, config).then((res) => {
      return res;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const browserNotification = (data) => {
  const urlPath = `kavishalawebnotifaction/savetoken/`;
  const url = `https://admin.kavishala.in/${urlPath}`;

  try {
    const response = axios.post(url, data).then((response) => {
      return response;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const getBooksSlug = (slug) => {
  const urlPath = `bookModule/public_single_book/${slug}/`;
  const url = `${baseURL}${urlPath}`;

  try {
    const response = axios.get(url).then((response) => {
      return response;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const allBooks = (slug) => {
  const urlPath = `reader/user_all_epub/${slug}/`;
  const url = `${baseURL}${urlPath}`;

  try {
    const response = axios.get(url).then((response) => {
      return response;
    });
    return response;
  } catch (error) {
    return error;
  }
};
export {
  login,
  Sociallogin,
  Varification,
  Sign,
  ForgetStep1,
  ForgetStep2,
  finalStep,
  writeBook,
  postWriteBook,
  putWriteBook,
  createBookChapter,
  getProfile,
  getAllBookChapter,
  updateBookChapter,
  deleteBookChapter,
  getBookChapter,
  bookDetailsGet,
  getPreview,
  bookDetailsUpdate,
  getAllBookDetails,
  publish,
  changePassword,
  getAllbooks,
  userCreatePayment,
  paymentFinalStep,
  bookIsPaid,
  browserNotification,
  getBooksSlug,
  allBooks,
};
