import React from "react";
import Editors from "../../Component/Editor/Editors";
import PublishStep3 from "../../Component/Publish/PublishStep3";
const BackCover = () => {
  return (
    <div>
      <Editors />
      <PublishStep3 />
    </div>
  );
};

export default BackCover;
