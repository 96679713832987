import React from "react";
import { Helmet } from "react-helmet";
var logo = "/images/favicon.ico";
const SEO = (props) => {
  return (
    <div>
      <Helmet>
        <link rel="icon" href={logo} />
        <link rel="apple-touch-icon" href={logo} />
        <title>{props.title || "Writro"}</title>

        <meta property="og:type" content={props.og_type || "article"} />

        <meta property="og:locale" content="en" />
        <meta name="description" content={props.og_description} />
        <meta name="author" content={props.author || "Witro"} />

        {/* FACEBOOK TAGS */}
        <meta property="fb:app_id" content="2593857177316284" />
        <meta
          property="fb:pages"
          content="1366164690108763,127764270624437,464599680252351"
        />
        {/* (Facebook Page ID) */}
        <meta property="og:title" content={props.og_title} />
        <meta property="og:description" content={props.og_description} />
        <meta property="og:url" content={props.og_url} />
        <meta property="og:site_name" content="writro.com" />
        <meta
          property="og:image"
          content={props.og_image || "https://writro.com/images/writro.png"}
        />
        <meta property="og:image:width" content="800" />
        <meta property="og:image:height" content="400" />
        <meta
          property="og:image:alt"
          content={props.og_image || "https://writro.com/images/writro.png"}
        />
        <meta
          property="article:publisher"
          content={props.author_facebook || "Writro"}
        />
        <meta property="article:section" content={props.category} />
        <meta property="article:published_time" content={props.published_on} />
        {/* TWITTER META TAGS */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:description" content={props.og_description} />

        <meta
          name="twitter:title"
          content={
            props.og_title ? `${props.og_title.slice(0, 110)}...` : "Writro"
          }
        />
        <meta name="twitter:site" content="@Writro" />
        <meta
          name="twitter:image"
          content={props.og_image || "https://writro.com/images/writro.png"}
        />
        <meta
          name="twitter:creator"
          content={props.author_twitter || "@Writro"}
        />
      </Helmet>
    </div>
  );
};

export default SEO;
