import React, { useEffect, useState } from "react";
import StayFearless from "../../assets/Publish/StayFearless.svg";
import Book from "../../assets/Publish/bookLover.svg";
import "../../Component/BookInfo/BookInfo.scss";
import Button from "@mui/material/Button";
import { getAllBookDetails } from "../../Api/Authentication";
import { useNavigate, useParams } from "react-router-dom";
const BooksInfo = () => {
  const [data, setdata] = useState({});
  const navigate = useNavigate();
  const { slug } = useParams();
  useEffect(() => {
    const loadData = () => {
      getAllBookDetails(slug).then((res) => {
        setdata(res?.data?.data);
      });
    };
    loadData();
  }, [slug]);

  return (
    <div className="bookInfo_mainContainer">
      <div className="bookImageContainer">
        <img src={data?.cover_image} alt="" className="bookImage" />
      </div>
      <div className="bookContaintContainer">
        <h1 className="Book_Heading">{data?.title}</h1>
        <h6 className="Book_AuthorName">By {data?.author_name}</h6>
        <div className="BookDetails">
          <h2 className="totalWords">Total Words : {data?.word_count}</h2>
          <h2 className="totalWords">Total Pages : {data?.page_count}</h2>
        </div>
        <p className="BookDescription">{data?.description}</p>
      </div>
      <div className="Book_PublishContainer">
        <img src={Book} alt="" className="bookLover" />
        <Button
          variant="contained"
          className="continue_publish"
          onClick={() => navigate(`/finalLanding/${slug}`)}
        >
          CONTINUE TO PUBLISH
        </Button>
        <h1 className="or"> OR</h1>
        <Button
          variant="contained"
          className="continue_back_cover"
          onClick={() => navigate(`/backCoverPage/${slug}`)}
        >
          MAKE A BACK COVER
        </Button>
      </div>
    </div>
  );
};

export default BooksInfo;
