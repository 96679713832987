import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../../images/navbar/navbarlogo.png";
import "../../Component/Editor/Editors.css";
import Cover from "../../assets/Editors/cover.svg";
import Backcover from "../../assets/Editors/bookCover.svg";
import Editor from "../../assets/Editors/editor.svg";
import Info from "../../assets/Editors/bookInfo.svg";
import Publish from "../../assets/Editors/publish.svg";
import { useNavigate, useParams } from "react-router-dom";
import { getAllBookChapter } from "../../Api/Authentication";
import EditorsModal from "./EditorsModal";
import {
  writeBook,
  createBookChapter,
  bookDetailsUpdate,
  bookDetailsGet,
} from "../../Api/Authentication";
import { ToastContainer, toast } from "react-toastify";
const Editors = ({
  handleAddandSave,
  content,
  chapterTitle,
  isRefresh,
  imageURLS,
  nextFunction,
  setNextStep,
  nextStep,
}) => {
  const pathname = window?.location?.pathname;
  const navigate = useNavigate();
  const { slug } = useParams();
  const [toggle, setToggle] = useState(false);
  const [step, setStep] = useState(1);
  const [selected, setSelected] = useState();
  const [allChapterData, setAllChapterData] = useState([]);

  const saveSlug = localStorage?.getItem("slug");
  useEffect(() => {
    const loadData = () => {
      getAllBookChapter(slug).then((res) => {
        setAllChapterData(res?.data?.results);
        // setEditorsDetails(res?.data);
      });
    };
    loadData();
  }, [slug, isRefresh, imageURLS]);

  const handleNext = (count) => {
    setStep(step + count);
    if (step === 0) {
      navigate(`/coverPage/${slug}`);
    } else if (step === 1) {
      // handleAddandSave();
      if (imageURLS) navigate(`/editors/${slug}`);
      setStep(0);
    } else if (step === 2) {
      handleAddandSave();
      if (allChapterData.length !== 0) navigate(`/bookInfo/${slug}`);
      setStep(2);
    } else if (step === 3) {
      navigate(`/backCoverPage/${slug}`);
    } else if (step === 4) {
      navigate(`/finalLanding/${slug}`);
    }
  };

  useEffect(() => {
    const pathname = window?.location?.pathname;
    const routData = () => {
      if (pathname === `/coverPage/${slug}`) {
        setStep(1);
      } else if (pathname === `/editors/${slug}`) {
        setStep(2);
      } else if (pathname === `/bookInfo/${slug}`) {
        setStep(3);
      } else if (pathname === `/backCoverPage/${slug}`) {
        setStep(4);
      } else if (pathname === `/finalLanding/${slug}`) {
        setStep(5);
      }
    };
    routData();
  }, [pathname]);

  return (
    <div className="Editors-navbar">
      <Link to="/">
        <img src={Logo} className="nav-logo" alt="" />
      </Link>
      <ul className="Editor-center-menu">
        <div
          id={pathname === `/coverPage/${slug}` ? "selected-btn" : null}
          onClick={() => navigate(`/coverPage/${slug}`)}
          className="Editor-list-item"
        >
          <img className="CoverImg" src={Cover} alt="" />
          <li className="list-item">Cover</li>
        </div>

        <div
          onClick={() =>
            imageURLS === null ? "" : navigate(`/editors/${slug}`)
          }
          className="Editor-click-item"
          id={pathname === `/editors/${slug}` ? "selected-btn" : null}
          // style={{ background: "#4E4E4E" }}
        >
          <img className="CoverImg" src={Editor} alt="" id="editors_icon" />
          <li className="list-item">Editor</li>
        </div>
        <div
          onClick={() =>
            allChapterData.length === 0
              ? toast("Please complete previous step", {
                  closeOnClick: false,
                  closeButton: false,
                  type: toast.TYPE.ERROR,
                  autoClose: 500,
                  position: toast.POSITION.BOTTOM_CENTER,
                  hideProgressBar: false,
                  className: "toaster",
                })
              : navigate(`/bookInfo/${slug}`)
          }
          className="Editor-click-item"
          id={pathname === `/bookInfo/${slug}` ? "selected-btn" : null}
        >
          <img className="CoverImg" src={Info} alt="" />
          <li className="list-item">
            {/* <Link to="/BookInfo" className="linkText"> */}
            Book Info
            {/* </Link> */}
          </li>
        </div>
        <div
          to="#"
          className="Editor-click-item"
          onClick={() =>
            allChapterData.length === 0
              ? toast("Please complete previous step", {
                  closeOnClick: false,
                  closeButton: false,
                  type: toast.TYPE.ERROR,
                  autoClose: 500,
                  position: toast.POSITION.BOTTOM_CENTER,
                  hideProgressBar: false,
                  className: "toaster",
                })
              : navigate(`/backCoverPage/${slug}`)
          }
          id={pathname === `/backCoverPage/${slug}` ? "selected-btn" : null}
        >
          <img className="CoverImg" src={Backcover} alt="" />
          <li className="list-item">
            {/* <Link to="#" className="linkText"> */}
            Back Cover
            {/* </Link> */}
          </li>
        </div>
        <div
          className="Editor-click-item"
          onClick={() =>
            allChapterData.length === 0
              ? toast("Please complete previous step", {
                  closeOnClick: false,
                  closeButton: false,
                  type: toast.TYPE.ERROR,
                  autoClose: 500,
                  position: toast.POSITION.BOTTOM_CENTER,
                  hideProgressBar: false,
                  className: "toaster",
                })
              : navigate(`/finalLanding/${slug}`)
          }
          id={pathname === `/finalLanding/${slug}` ? "selected-btn" : null}
        >
          <img className="CoverImg" src={Publish} alt="" />
          <li className="list-item">
            <Link to="#" className="linkText">
              Publish
            </Link>
          </li>
        </div>
      </ul>
      <div className="nav-login-section">
        <div className="nav-signup">
          <div className="linkText" onClick={() => handleNext(1)}>
            Save and Continue
          </div>
        </div>
      </div>
    </div>
  );
};

export default Editors;
