import React, { useEffect, useState } from "react";
import Navbar from "../../Component/Navbar1/Navbar";
import UserProfiles from "../../Component/UserProfile/UserProfiles";
import { getProfile } from "../../Api/Authentication";
import SEO from "../../SEO";
const Profile = () => {
  const [profileData, setprofileData] = useState([]);
  const [profile, setprofile] = useState({});
  useEffect(() => {
    const loadData = () => {
      getProfile().then((res) => {
        setprofileData(res?.data?.book);
        setprofile(res?.data?.data?.user);
      });
    };
    loadData();
  }, []);
  console.log("kjhgvbn", profile, profileData);
  return (
    <div>
      <SEO
        title={`${profile?.slug?.toUpperCase()} | Writro`}
        og_url={"/profile"}
        og_type="e-book"
        og_title={`${profile?.slug?.toUpperCase()} | Writro`}
        og_description={"Writro: Where Every Writer's Journey Begins"}
        og_image="https://writro.com/images/writro.png"
      />
      <Navbar />
      <UserProfiles profile={profile} profileData={profileData} />
    </div>
  );
};

export default Profile;
