import React from "react";
import Forget from "../../Component/ForgetPaswordStep2/Forget";
import Navbar from "../../Component/Navbar1/Navbar";
const ForgetPaswordStep2 = () => {
  return (
    <div>
      <Navbar />
      <Forget />
    </div>
  );
};

export default ForgetPaswordStep2;
