import React, { useState, useEffect } from "react";
import "../../Component/Publish/Publish.css";
import { Link } from "react-router-dom";
import Publish from "../../assets/Publish/Publish.png";
import Arrow from "../../assets/Publish/arrow.svg";
import Passion from "../../assets/Publish/PassionLove.svg";
import Bridge from "../../assets/Publish/BeigeAbout.svg";
import StayFearless from "../../assets/Publish/StayFearless.svg";
import Canva from "../../assets/Publish/Canva.png";
import CanvaLogo from "../../assets/Publish/CanvaLogo.svg";
import LeftArow from "../../assets/Publish/leftArrow.svg";
import { useNavigate, useParams } from "react-router-dom";
import { bookDetailsUpdate, bookDetailsGet } from "../../Api/Authentication";
import { ToastContainer, toast } from "react-toastify";
const PublishStep4 = ({
  setImageURLs,
  imageURLS,
  setIsRefresh,
  isRefresh,
  nextFunction,
  setNextStep,
  nextStep,
}) => {
  const navigate = useNavigate();
  const { slug } = useParams();

  const [img, setImg] = useState();
  const [data, setData] = useState();
  const handleImage = (e) => {
    const image = e.target.files[0];
    setImg(image);
    setImageURLs(URL.createObjectURL(e.target.files[0]));
    setIsRefresh(!isRefresh);
  };
  const handleNext = () => {
    navigate(`/editors/${slug}`);
  };
  const saveSlug = localStorage?.getItem("slug");
  const handleSubmit = () => {
    const formData = new FormData();
    formData.append("cover_image", img);
    bookDetailsUpdate(slug, formData)
      .then((res) => {
        if (res?.status === 200) {
          navigate(`/editors/${slug}`);
          setIsRefresh(!isRefresh);
        }
      })
      .catch((e) =>
        toast(e?.response?.data?.msg, {
          closeOnClick: false,
          closeButton: false,
          type: toast.TYPE.ERROR,
          autoClose: 500,
          position: toast.POSITION.BOTTOM_CENTER,
          hideProgressBar: false,
          className: "toaster",
        })
      );
  };
  useEffect(() => {
    const loadData = () => {
      bookDetailsGet(slug).then((res) => {
        setImageURLs(res?.data?.cover_image);
      });
    };
    loadData();
  }, []);

  return (
    <div className="PublishContainer">
      <div className="cover-rightContainer">
        <div className="ArrowContainer" onClick={() => navigate(`/publish`)}>
          <img className="coverArrowImg" src={Arrow} alt="" />
          <p className="back">Back</p>
        </div>
        <div className="HeadContainer">
          <h1 className="publishHead4">
            A Great Book Cover Leads to A Greater Audience
          </h1>
        </div>
        <div className="imageConatiner">
          <img
            className="canvaImage"
            src={imageURLS === null ? Bridge : imageURLS}
            alt=""
          />
        </div>
        <div>
          <div style={{ margin: "auto" }}>
            <p className="create-canva">
              Create your own stylish cover with Canva
            </p>
          </div>
          <div className="Canva-image-container">
            <img src={CanvaLogo} alt="" className="canva-image" />
            <p className="create-canva2">Create with Canva</p>
            <img src={LeftArow} alt="" className="canva-LeftArow" />
          </div>
        </div>
        <div className="ExtraOptionsContainer">
          <h6 className="Extra_or">or</h6>
          <div className="Add-imageSection">
            <input
              type="file"
              name="img"
              id="fileUpdate"
              onChange={handleImage}
              className=""
              style={{ display: "none" }}
              multiple
            />
            <div className="cover_btn_container">
              <label for="fileUpdate" className="uploadImages">
                <div className="UploadBtn">Browse</div>
              </label>

              {img === undefined ? (
                imageURLS !== null ? (
                  <label className="uploadImages">
                    <div className="UploadBtn" onClick={handleNext}>
                      Next
                    </div>
                  </label>
                ) : (
                  ""
                )
              ) : (
                <label className="uploadImages">
                  <div className="UploadBtn" onClick={handleSubmit}>
                    Save & Next
                    <ToastContainer />
                  </div>
                </label>
              )}
              {imageURLS !== null ? (
                ""
              ) : (
                <div className="indicators">
                  click on Browse to select image
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PublishStep4;
