import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { ForgetStep1 } from "../../Api/Authentication";
import LoginUser from "../../assets/loginSignup/loginpageF.png";
import { useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
const Forget = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");

  const [phone_number, setPhone_number] = useState("");
  const handleSubmit = (e) => {
    e.preventDefault();

    const userData = {
      phone_number: `91${phone_number}`,
      email,
    };

    ForgetStep1(userData)
      .then((res) => {
        if (res?.status === 200) {
          navigate(`/forgotPaswordStep2`);
        }
      })
      .catch((e) =>
        toast(e?.response?.data?.msg, {
          closeOnClick: false,
          closeButton: false,
          type: toast.TYPE.ERROR,
          autoClose: 1000,
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: false,
          className: "toaster",
        })
      );
  };
  localStorage.setItem("phone_number", "91" + phone_number);
  localStorage.setItem("email", email);
  return (
    <div class="loginPage">
      <form class="form-section" onSubmit={handleSubmit}>
        <h1>Forget Password</h1>

        <div class="gap1"></div>
        <div class="form-control">
          <label for="password">Email</label>
          <input
            type="email"
            name="email"
            id="email"
            placeholder="Enter your email or username"
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>

        <button type="submit" className="LoginBtn">
          Next
          <ToastContainer />
        </button>
      </form>
      <div class="image-div">
        <img src={LoginUser} alt="" />
      </div>
    </div>
  );
};

export default Forget;
