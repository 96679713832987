import React from "react";
import Navbar from "../../Component/Navbar1/Navbar";
import HeroBanner from "../../Component/HeroBanner/HeroBanner";
import SampleBooks from "../../Component/HeroBanner/SampleBooks";
import Footer from "../../Component/Footer/Footer";
import SEO from "../../SEO";
const Services = () => {
  return (
    <div>
      <SEO
        title={`Services | Writro`}
        og_url={"/services"}
        og_type="e-book"
        og_title={`Services | Writro`}
        og_description={"Writro: Where Every Writer's Journey Begins"}
        og_image="https://writro.com/images/writro.png"
      />
      <Navbar />
      <HeroBanner />
      <SampleBooks />
      <Footer />
    </div>
  );
};

export default Services;
