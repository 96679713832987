import React from "react";
import "../../styles/Banner.scss";
import Button from "@mui/material/Button";
// import SendIcon from "@mui/material/Send";
import banner from "../../assets/homepage/banner image.png";
import google from "../../assets/homepage/mobPlaybtn.svg";
const HeroBanner = () => {
  return (
    <div className="bannerContainer">
      <div className="bannerContainerLeft">
        <div className="contentBox">
          <h1 className="contentHeading">Keep Reading & Learning</h1>
          <p className="contentParagraph">
            Writro is a FREE platform where you can share your views and
            thoughts beyond any boundaries.
          </p>
          <p className="contentParagraph">
            People are not handy with long contents.
          </p>
        </div>
        <div className="buttonBox">
          <Button
            variant="contained"
            className="DownloadBtn"
            onClick={() =>
              window.open(
                "https://play.google.com/store/apps/details?id=com.foreantech.writro"
              )
            }
          >
            Download Now
          </Button>
          <img
            src={google}
            alt="playbtn"
            className="playStoreBtn"
            onClick={() =>
              window.open(
                "https://play.google.com/store/apps/details?id=com.foreantech.writro"
              )
            }
          />
        </div>
      </div>
      <div className="bannerContainerRight">
        <img src={banner} alt="banner" className="bannerImage" />
      </div>
    </div>
  );
};

export default HeroBanner;
