import React, { useState, useRef, useEffect } from "react";
import "../../Component/Editor/Editors.css";
import {
  EditorState,
  ContentState,
  convertFromHTML,
  Modifier,
  convertToRaw,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "draft-js/dist/Draft.css";
import EditPencil from "../../assets/Editors/edit.svg";
import Save from "../../assets/Editors/save.svg";
import CheckIcon from "@mui/icons-material/Check";
import { stateToHTML } from "draft-js-export-html";
import { stateFromHTML } from "draft-js-import-html";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  bookDetailsGet,
  bookDetailsUpdate,
  createBookChapter,
  updateBookChapter,
  getAllBookChapter,
  deleteBookChapter,
} from "../../Api/Authentication";

import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  border: "2px solid #fff",
  boxShadow: 24,
  p: 4,
  opacity: "unset",
  transition: "unset",
};
const EditorsModal = ({
  content,
  setContent,
  chapterTitle,
  setChapterTitle,
}) => {
  const navigate = useNavigate();
  const { slug } = useParams();
  // const [content, setContent] = useState("");
  const [edit, setEdit] = useState(false);
  const [nextCount, setNextCount] = useState(0);
  const [isRefresh, setIsRefresh] = useState(false);
  const [editorsDetails, setEditorsDetails] = useState(() => {
    return EditorState.createEmpty();
  });
  const [title, setTitle] = useState();
  // const [chapterTitle, setChapterTitle] = useState();
  const [chapterData, setChapterData] = useState();
  const [chapterDelete_slug, setchapterDelete_slug] = useState({});
  const [allChapterData, setAllChapterData] = useState([]);
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [open2, setOpen2] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  const handleOpen = (data) => {
    setchapterDelete_slug(data);
    setOpenModal(true);
  };
  const handleModalClose = () => setOpenModal(false);
  const anchorRef2 = React.useRef(null);
  const editor = useRef(null);
  let isImageAdded = false;
  const setSelection = () => {
    const selection = document.getSelection();
    const range = document.createRange();
    range.setStart(editor.current.editor.editorContainer, 0);
    range.setEnd(editor.current.editor.editorContainer, 0);
    selection.removeAllRanges();
    selection.addRange(range);
  };
  var contentState;
  const htmlContent = "";
  const contentBlocks = htmlToDraft(htmlContent);
  contentState = ContentState.createFromBlockArray(contentBlocks);

  const fontSizeStyleMap = {
    "8px": {
      fontSize: "8px",
    },
    "12px": {
      fontSize: "12px",
    },
    "16px": {
      fontSize: "16px",
    },
    // Add more sizes as needed
  };

  const handleEditorChange = (newEditorState) => {
    setEditorState(newEditorState);
    const contentState = editorState.getCurrentContent();

    const contentHtml = stateToHTML(contentState);
    setContent(contentHtml);
  };
  const htmlConvert = (description) => {
    setChapterData(description?.slug);
    localStorage.setItem("chapter_id", description?.slug);
    setChapterTitle(description?.title);
    const htmlContent = description?.content;
    const contentBlocks = htmlToDraft(htmlContent);
    contentState = ContentState.createFromBlockArray(contentBlocks);
    const contentHtml = stateToHTML(contentState);
    setContent(contentHtml);
    setEditorState(EditorState.createWithContent(contentState));
  };

  const [editorState, setEditorState] = useState(
    EditorState.createWithContent(contentState)
  );

  const getContentAsHtml = (conte) => {
    const contentState = editorState.getCurrentContent();
    const contentHtml = stateToHTML(contentState);

    setContent(contentHtml);
    return contentHtml;
  };

  const uploadCallbackFunc = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    return new Promise((resolve) => {
      reader.onload = () => {
        const form_data = new FormData();
        form_data.append("file", file);
        resolve({
          data: {
            link: reader?.result,
          },
        });

        setSelection();
        isImageAdded = true;
      };
    });
  };

  const toolbar = {
    list: { inDropdown: false },
    image: {
      uploadCallback: uploadCallbackFunc,
    },
    embedded: {},
    inline: {
      inDropdown: false,
      className: undefined,
      component: undefined,
      dropdownClassName: undefined,
      options: [
        "bold",
        "italic",
        "underline",
        // "strikethrough",
        "subscript",
        "superscript",
      ],
    },
    options: [
      "history",
      "inline",
      "blockType",
      "fontSize",
      "colorPicker",
      "list",
      "textAlign",
      "image",
      "fontFamily",
    ],

    fontSize: {},
  };
  const handleClick = () => {
    setEdit(true);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handleToggle2 = (data) => {
    setchapterDelete_slug(data);

    setOpen2((prevOpen2) => !prevOpen2);
  };

  const handleClose2 = (event) => {
    if (anchorRef2.current && anchorRef2.current.contains(event.target)) {
      return;
    }

    setOpen2(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);
  const prevOpen2 = React.useRef(open2);
  React.useEffect(() => {
    if (prevOpen2.current === true && open2 === false) {
      anchorRef2.current?.focus();
    }

    prevOpen2.current = open2;
  }, [open2]);
  useEffect(() => {
    const loadData = () => {
      bookDetailsGet(slug).then((res) => {
        setEditorsDetails(res?.data);
      });
    };
    loadData();
  }, [edit]);
  const handleSubmit = () => {
    const formData = new FormData();
    formData.append("title", title);
    bookDetailsUpdate(slug, formData)
      .then((res) => {
        if (res?.status === 200) {
          setEdit(false);
          toast("Book Name Updated", {
            closeOnClick: false,
            closeButton: false,
            type: toast.TYPE.SUCCESS,
            autoClose: 500,
            position: toast.POSITION.TOP_RIGHT,
            className: "sucesstoaster",
          });
        }
      })
      .catch((e) =>
        toast(e.response.data.msg, {
          closeOnClick: false,
          closeButton: false,
          type: toast.TYPE.ERROR,
          autoClose: 500,
          position: toast.POSITION.BOTTOM_CENTER,
          hideProgressBar: false,
          className: "toaster",
        })
      );
  };

  const handleAddandSave = () => {
    const formData = new FormData();
    formData.append("title", chapterTitle);
    formData.append(
      "content",
      draftToHtml(convertToRaw(editorState.getCurrentContent()))
    );
    if (chapterData === undefined) {
      createBookChapter(slug, formData)
        .then((res) => {
          if (res?.status === 200) {
            setChapterData(res?.data?.data?.slug);
            setIsRefresh(!isRefresh);
            toast("Saved");
          }
        })
        .catch((e) =>
          toast(e.response.data.msg.title[0], {
            closeOnClick: false,
            closeButton: false,
            type: toast.TYPE.ERROR,
            autoClose: 500,
            position: toast.POSITION.BOTTOM_CENTER,
            hideProgressBar: false,
            className: "toaster",
          })
        );
    } else {
      updateBookChapter(slug, chapterData, formData)
        .then((res) => {
          if (res?.status === 200) {
            setChapterData(res?.data?.data?.slug);
            setIsRefresh(!isRefresh);
            toast.success("Updated", {
              hideProgressBar: false,
              className: "sucesstoaster",
            });
          }
        })
        .catch((e) =>
          toast(e.response.data.msg.title[0], {
            closeOnClick: false,
            closeButton: false,
            type: toast.TYPE.ERROR,
            autoClose: 500,
            position: toast.POSITION.BOTTOM_CENTER,
            hideProgressBar: false,
            className: "toaster",
          })
        );
    }
  };

  const handleNewChapter = () => {
    const formData = new FormData();
    formData.append("title", chapterTitle);
    // formData.append("content", content);
    formData.append(
      "content",
      draftToHtml(convertToRaw(editorState.getCurrentContent()))
    );
    if (chapterData === undefined) {
      createBookChapter(slug, formData)
        .then((res) => {
          if (res?.status === 200) {
            setChapterData(res?.data?.data?.slug);
            setChapterData(undefined);
            setChapterTitle("");
            setEditorState("");
            setIsRefresh(!isRefresh);
            toast("Saved");
          }
        })
        .catch((e) =>
          toast(e.response.data.msg, {
            closeOnClick: false,
            closeButton: false,
            type: toast.TYPE.ERROR,
            autoClose: 500,
            position: toast.POSITION.BOTTOM_CENTER,
            hideProgressBar: false,
            className: "toaster",
          })
        );
    } else {
      updateBookChapter(slug, chapterData, formData)
        .then((res) => {
          if (res?.status === 200) {
            setChapterData(res?.data?.data?.slug);
            localStorage.removeItem("chapter_id");
            setIsRefresh(!isRefresh);
            toast.success("Updated", {
              hideProgressBar: false,
              className: "sucesstoaster",
            });
            setChapterTitle("");
            setChapterData(undefined);
            setEditorState("");
          }
        })
        .catch((e) =>
          toast(e.response.data.msg, {
            closeOnClick: false,
            closeButton: false,
            type: toast.TYPE.ERROR,
            autoClose: 500,
            position: toast.POSITION.BOTTOM_CENTER,
            hideProgressBar: false,
            className: "toaster",
          })
        );
    }
  };

  useEffect(() => {
    const loadData = () => {
      getAllBookChapter(slug, chapterData).then((res) => {
        setAllChapterData(res?.data?.results);
        // setEditorsDetails(res?.data);
      });
    };
    loadData();
  }, [slug, chapterData, isRefresh]);
  const deleteChapter = () => {
    deleteBookChapter(slug, chapterDelete_slug?.slug).then((res) => {
      if (res?.status === 200) {
        setIsRefresh(!isRefresh);
        handleModalClose();
      }
    });
  };

  const handlenextStep = () => {
    console.log("yes running");
    setNextCount(nextCount + 1);
  };
  const handleskipFunction = () => {
    localStorage.setItem("skip", 10);
    setNextCount(10);
  };

  return (
    <div className="Editors-modal">
      <div className="Editors-left-panel">
        <h1 className="Book-Structure">Book Structure</h1>
        {allChapterData?.map((chapterName) => {
          return (
            <div className="Book-Structure-contents">
              <h1
                className="book_chapter_names"
                style={{ cursor: "pointer" }}
                onClick={() => htmlConvert(chapterName)}
              >
                {chapterName?.title}
              </h1>
              <DeleteIcon
                className="moreVerticon"
                // onClick={() => handleToggle2(chapterName)}
                onClick={() => handleOpen(chapterName)}
                ref={anchorRef2}
              />
              <Popper
                open={open2}
                anchorEl={anchorRef2.current}
                role={undefined}
                placement="bottom-start"
                transition
                disablePortal
                className="optionModule2"
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    // style={{
                    //   transformOrigin:
                    //     placement === "bottom-start" ? "left top" : "left bottom",
                    // }}
                  >
                    <Paper>
                      <ClickAwayListener onClickAway={handleClose2}>
                        <MenuList
                          autoFocusItem={open2}
                          id="composition-menu"
                          aria-labelledby="composition-button"
                          onKeyDown={handleListKeyDown}
                        >
                          <MenuItem onClick={handleOpen} className="chapters">
                            Delete
                          </MenuItem>
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </div>
          );
        })}
      </div>

      <div className="Editors-right-panel">
        <div className="EditTitle">
          {!edit ? (
            <div className="edit-pencil-container">
              <header className="App-header">{editorsDetails?.title}</header>
              <img
                className="EditPencil"
                src={EditPencil}
                alt=""
                onClick={handleClick}
              />
              {!localStorage.getItem("skip") && nextCount === 0 ? (
                <div className="pencil-indicators">
                  Change Book Name
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <span
                      style={{ color: "blue", cursor: "pointer" }}
                      onClick={handlenextStep}
                    >
                      Next
                    </span>
                    <span
                      style={{ color: "red", cursor: "pointer" }}
                      onClick={handleskipFunction}
                    >
                      Skip
                    </span>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          ) : (
            <>
              <input
                type="text"
                className="chapterFeild"
                placeholder="Add Title"
                value={title === undefined ? editorsDetails?.title : title}
                onChange={(e) => setTitle(e.target.value)}
                required
              />
              <CheckIcon className="correct" onClick={handleSubmit} />
              <ToastContainer />
            </>
          )}
        </div>

        <Editor
          toolbarCustomButtons={[
            <>
              <div
                className="toolBarExtraBtn"
                onClick={handleToggle}
                ref={anchorRef}
              >
                <h1 style={{ fontSize: "18px" }}>+</h1>
              </div>

              <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                placement="bottom-start"
                transition
                disablePortal
                className="optionModule"
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin:
                        placement === "bottom-start"
                          ? "left top"
                          : "left bottom",
                    }}
                  >
                    <Paper>
                      <ClickAwayListener onClickAway={handleClose}>
                        <MenuList
                          autoFocusItem={open}
                          id="composition-menu"
                          aria-labelledby="composition-button"
                          onKeyDown={handleListKeyDown}
                        >
                          <MenuItem
                            onClick={handleNewChapter}
                            className="chapters"
                          >
                            New Chapter
                          </MenuItem>
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </>,
            <>
              <div className="toolBarSaveBtn">
                <img src={Save} alt="" onClick={handleAddandSave} />

                <ToastContainer
                  position="top-right"
                  autoClose={500}
                  hideProgressBar={false}
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable={false}
                  pauseOnHover
                  theme="light"
                />
              </div>
              {!localStorage.getItem("skip") && nextCount === 2 ? (
                <div className="save-chapter-indicators" style={{ left: "5%" }}>
                  Add New Chapter here
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <span
                      style={{ color: "blue", cursor: "pointer" }}
                      onClick={handlenextStep}
                    >
                      Next
                    </span>
                    <span
                      style={{ color: "red", cursor: "pointer" }}
                      onClick={handleskipFunction}
                    >
                      Skip
                    </span>
                  </div>
                </div>
              ) : (
                ""
              )}
              {!localStorage.getItem("skip") && nextCount === 1 ? (
                <div className="save-chapter-indicators">
                  Save Chapter here
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <span
                      style={{ color: "blue", cursor: "pointer" }}
                      onClick={handlenextStep}
                    >
                      Next
                    </span>
                    <span
                      style={{ color: "red", cursor: "pointer" }}
                      onClick={handleskipFunction}
                    >
                      Skip
                    </span>
                  </div>
                </div>
              ) : (
                ""
              )}
              <div className="chapterContainer">
                <input
                  type="text"
                  className="chapterFeild"
                  placeholder="Add Chapter Title"
                  name="title"
                  onChange={(e) => setChapterTitle(e.target.value)}
                  value={chapterTitle}
                  required
                />
              </div>
            </>,
          ]}
          // ref={editor}
          toolbarClassName="toolbar-class"
          toolbar={toolbar}
          toolbarHidden={false}
          editorState={editorState}
          onEditorStateChange={handleEditorChange}
          placeholder="Start writing..."
          editorStyle={{
            width: "100%",
            height: "400px",
            background: "#F8F8F8",
            border: "0.1px solid #000000",
            borderRadius: "22px",
            paddingLeft: "2.5%",
          }}
        />
      </div>

      <div>
        <Modal
          open={openModal}
          onClose={handleModalClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h3" component="h2">
              Your "{chapterDelete_slug?.title}" will be removed
            </Typography>
            <Typography
              id="modal-modal-description"
              sx={{ mt: 2, fontSize: 18 }}
            >
              Do you want to remove this "{chapterDelete_slug?.title}" ?
            </Typography>
            <div className="btn_modal_container">
              <Button
                variant="contained"
                onClick={handleModalClose}
                style={{
                  borderRadius: 5,
                  backgroundColor: "#DC3545",
                  fontSize: "14px",
                  margin: "0 5%",
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                onClick={deleteChapter}
                style={{
                  borderRadius: 5,
                  backgroundColor: "#F89920",
                  fontSize: "14px",
                }}
              >
                Delete
              </Button>
            </div>
          </Box>
        </Modal>
      </div>
    </div>
  );
};

export default EditorsModal;
