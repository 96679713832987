import React, { useState, useEffect } from "react";
import "./Profile.scss";
import { getProfile } from "../../Api/Authentication";
import bookCover from "../../assets/homepage/bookcover.jpeg";
import { useNavigate } from "react-router-dom";
const UserProfiles = ({ profile, profileData }) => {
  const navigate = useNavigate();

  const handleMove = (slug) => {
    navigate(`/editors/${slug}`);
  };
  return (
    <div className="row">
      <div className="Col3">
        <div className="profileBox">
          {/* <div className="edit">
            <p className="edit_text">EDIT</p>
          </div> */}
          <div className="ProfileBox">
            <p className="Profile_Name">{profile?.name}</p>
          </div>
          <div className="box_container">
            <div className="box">
              <div className="data_box">
                <p className="follow_data">0</p>
                <p className="follow_text">Followers</p>
              </div>
              <div className="data_box">
                <p className="follow_data">0</p>
                <p className="follow_text">Following</p>
              </div>
              <div className="data_box">
                <p className="follow_data">0</p>
                <p className="follow_text">Book</p>
              </div>
              <div className="data_box">
                <p className="follow_data">0</p>
                <p className="follow_text">Pending Book</p>
              </div>
            </div>
          </div>
          <div className="Profile_btn">
            <button className="Profile_btn_1">Our Services</button>
            <button className="Profile_btn_2" onClick={() => navigate("/shop")}>
              Shop
            </button>
          </div>
        </div>
      </div>
      <div className="col9">
        {profileData.map((value) => {
          return (
            <div className="book_container">
              <div className="img_container">
                <img src={bookCover} alt="" className="book-image" />
                <div className="book_details-container">
                  <p className="book">{value?.title}</p>
                  <p className="book">{value?.author_name}</p>
                </div>
              </div>
              <div className="Book_details">
                <p className="book_Author">{value?.author_name}</p>
                <p className="drafts">Drafts {value?.word_count} words</p>
                <button className="btn" onClick={() => handleMove(value?.slug)}>
                  Continue wriiting
                </button>
              </div>
              <div className="Book_details">
                <p className="book_Author"> Avg. words</p>
                <p className="book_Author">{value?.word_count}</p>
              </div>
              <div className="Book_details">
                <p className="book_Author">Page</p>
                <p className="book_Author">{value?.page_count}</p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default UserProfiles;
// value?.cover_image ? value?.cover_image :
