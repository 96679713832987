import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../images/navbar/navbarlogo.png";
import "./Footer.css";
const Footer = () => {
  return (
    <div className="fotter-main">
      <div className="footerRow">
        <div className=" footerCol col-md-3 col-lg-2 col-xl-3 ">
          <img src={Logo} className="nav-logo" alt="" />
          <div className="footer-icon mx-auto mb-4 ml-1 d-flex justify-content-md-between"></div>
        </div>

        <div className="about-other-responsive-design ">
          <div className="footerCol">
            <h6 className="HeadingsAbout">Learn</h6>
            <p className="footer-text">
              <a
                href=" https://kavishala.in/communities-Collaborations"
                className="text-reset"
                target="_blank"
              >
                FAQ
              </a>
            </p>
            <p className="footer-text">
              <a
                href=" https://kavishala.in/communities-privacy-policy"
                className="text-reset"
                target="_blank"
              >
                Community
              </a>
            </p>
            <p className="footer-text">
              <a
                href=" https://kavishala.in/communities-Terms-Conditions"
                className="text-reset"
                target="_blank"
              >
                Contact Us
              </a>
            </p>
            <p className="footer-text">
              <a
                href=" https://kavishala.in/careers"
                className="text-reset"
                target="_blank"
              >
                Resources
              </a>
              {/* {`/p/about`} */}
            </p>
          </div>
          <div className="other-for-responsive ">
            <h6 className="HeadingsOthers">Company</h6>
            <p className="footer-text">
              <a
                href="  https://kavishala.in/"
                className="text-reset"
                target="_blank"
              >
                About Us
              </a>
            </p>
            <p className="footer-text">
              <a
                href=" https://store.kavishala.in/"
                className="text-reset"
                target="_blank"
              >
                Careers
              </a>
            </p>
            <p className="footer-text">
              <a
                href=" https://ambassadors.kavishala.in/"
                className="text-reset"
                target="_blank"
              >
                Terms & Conditions
              </a>
            </p>
            <p className="footer-text">
              <a
                href=" http://app.kavishala.in/"
                className="text-reset"
                target="_blank"
              >
                Privacy Policy
              </a>
            </p>
          </div>
        </div>

        <div className="heading-footer">
          <h6 className="HeadingsOthers">Company</h6>
          <p className="footer-text">
            <a
              href="  https://kavishala.in/"
              className="text-reset"
              target="_blank"
            >
              About Us
            </a>
          </p>
          <p className="footer-text">
            <a
              href=" https://store.kavishala.in/"
              className="text-reset"
              target="_blank"
            >
              Careers
            </a>
          </p>
          <p className="footer-text">
            <a
              href=" https://ambassadors.kavishala.in/"
              className="text-reset"
              target="_blank"
            >
              Terms & Conditions
            </a>
          </p>
          <p className="footer-text">
            <a
              href=" http://app.kavishala.in/"
              className="text-reset"
              target="_blank"
            >
              Privacy Policy
            </a>
          </p>
        </div>

        <div className="footerCol">
          <h6 className="HeadingsContact">Social</h6>

          <p className="footer-text">
            <a
              href=" http://app.kavishala.in/"
              className="text-reset"
              target="_blank"
            >
              Facebook
            </a>
          </p>
          <p className="footer-text">
            <a
              href=" http://app.kavishala.in/"
              className="text-reset"
              target="_blank"
            >
              Twitter
            </a>
          </p>
          <p className="footer-text">
            <a
              href=" http://app.kavishala.in/"
              className="text-reset"
              target="_blank"
            >
              Instagram
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
