import React, { useState } from "react";
import "../../Component/signup-form/Signup.css";
import LoginUser from "../../assets/loginSignup/loginpageF.png";
import { Sign } from "../../Api/Authentication";
import { useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import { ToastContainer, toast } from "react-toastify";
const SignUp = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phone_number, setPhone_number] = useState("");
  const [name, setName] = useState("");
  const [error, setError] = useState({ open: false, msg: "" });
  const [otp, setOtp] = useState("");
  // const [phone_number, setPhone_number] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    const userData = {
      otp,
      phone_number,
      name,
      email,
      password,
    };

    Sign(userData)
      .then((res) => {
        if (res?.status === 200) {
          localStorage.setItem("writroToken", res?.data?.token);
          localStorage.setItem("author_name", res?.data?.name);
          toast("Sign Up Succesfull", {
            closeOnClick: false,
            closeButton: false,
            type: toast.TYPE.SUCCESS,
            autoClose: 500,
            position: toast.POSITION.TOP_RIGHT,
            className: "sucesstoaster",
          });
          setTimeout(() => {
            navigate(`/publish`);
          }, 1000);
        }
      })
      .catch((e) =>
        toast(e?.response?.data?.msg, {
          closeOnClick: false,
          closeButton: false,
          type: toast.TYPE.ERROR,
          autoClose: 1000,
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: false,
          className: "toaster",
        })
      );
  };

  return (
    <div class="loginPage">
      <form class="form-section" onSubmit={handleSubmit}>
        <h1>Sign Up</h1>

        <div class="gap1"></div>

        <div class="form-control">
          <label for="password">Full Name</label>
          <TextField
            id="Name"
            // label="Contact Number"

            variant="standard"
            className="phone_number"
            placeholder="Full Name"
            name="name"
            onChange={(e) => setName(e.target.value)}
            required
          />
          <div class="gap1"></div>
          <label for="password">Phone Number</label>
          <TextField
            id="phone_number"
            // label="Contact Number"
            type="number"
            variant="standard"
            className="phone_number"
            placeholder="Enter your Phone Number"
            name="phone_number"
            onChange={(e) => setPhone_number(e.target.value)}
            onInput={(e) => {
              e.target.value = Math.max(0, parseInt(e.target.value))
                .toString()
                .slice(0, 10);
            }}
            required
          />
          {error.open && (
            <p className="error" style={{ color: "red" }}>
              {error.msg}
            </p>
          )}
          <div class="gap1"></div>
          <label for="password">Email</label>
          <input
            type="email"
            name="email"
            id="email"
            placeholder="Enter your email or username"
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div class="form-control">
          <label for="password">Password</label>
          <input
            type="password"
            name="password"
            id="password"
            placeholder="Enter your password"
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <button type="submit" className="LoginBtn">
          Sign Up
          <ToastContainer />
        </button>
      </form>
      <div class="image-div">
        <img src={LoginUser} alt="" />
      </div>
    </div>
  );
};

export default SignUp;
