import React, { useState } from "react";
import Google from "../../assets/loginSignup/google.png";
import FB from "../../assets/loginSignup/facebook.png";
import Twitter from "../../assets/loginSignup/twitter.png";
import LoginUser from "../../assets/loginSignup/loginpageF.png";
import "../../Component/Login/Login.css";
import { login, Sociallogin } from "../../Api/Authentication.js";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import GoogleLogin from "react-google-login";
import { gapi } from "gapi-script";
const Login = () => {
  const navigate = useNavigate();
  const [phone_number, setPhone_number] = useState("");
  const [emails, setEmails] = useState("");
  const [password, setPassword] = useState("");

  const clientId =
    "611382242495-eagoibk2jk64dqtee8sdgcrrmbe4u900.apps.googleusercontent.com";

  const responseGoogle = (response) => {
    console.log("response", response);
    // gapi.load("client:auth2", () => {
    //   gapi.auth2.init({ clientId: clientId });
    // });
    var auth2 = gapi.auth2.getAuthInstance();

    if (!auth2) {
      auth2 = gapi.auth2.init({
        client_id: clientId,
      });
    }

    const data = {
      user: {
        email: response.profileObj.email,
        username: response.profileObj.name,
        provider: "google",
        token: response.accessToken,
      },
    };
    Sociallogin(data).then((res) => {
      const response = res;
      console.log("res", res);
      if (response?.status === 200) {
        localStorage.setItem("writroToken", res?.data?.token);
        localStorage.setItem("author_name", res?.data?.username);
        toast("Login Succesfull", {
          autoClose: 1000,
          position: toast.POSITION.TOP_RIGHT,
        });

        setTimeout(() => {
          navigate(`/publish`);
        }, 1000);
      }
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    const userData = {
      login_using: "email",
      email: emails,
      phone_number,
      password,
    };

    login(userData)
      .then((res) => {
        if (res?.status === 200) {
          localStorage.setItem("writroToken", res?.data?.token);
          localStorage.setItem("author_name", res?.data?.name);
          toast("Login succesfull", {
            closeOnClick: false,
            closeButton: false,
            type: toast.TYPE.SUCCESS,
            autoClose: 500,
            position: toast.POSITION.TOP_RIGHT,
            className: "sucesstoaster",
          });
          setTimeout(() => {
            // window.location.href = "/Publish";
            navigate(`/publish`);
          }, 1000);
        }
      })
      .catch((e) =>
        toast(e?.response?.data?.msg, {
          closeOnClick: false,
          closeButton: false,
          type: toast.TYPE.ERROR,
          autoClose: 500,
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: false,
          className: "toaster",
        })
      );
  };

  return (
    <div className="loginPage">
      <form className="form-section" onSubmit={handleSubmit}>
        <h1>Login</h1>
        <div className="icons-div">
          {/* <img src={Google} alt="" /> */}
          {/* <img src={FB} alt="" />
          <img src={Twitter} alt="" /> */}
        </div>
        {/* <div className="gap1"></div> */}
        <div className="form-control">
          <label for="password">Email</label>
          <input
            type="text"
            name="email"
            id="phone_number"
            placeholder="Enter your email"
            onChange={(e) => setEmails(e.target.value)}
          />
        </div>
        <div className="form-control">
          <label for="password">Password</label>
          <input
            type="password"
            name="password"
            id="password"
            placeholder="Enter your password"
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <Link to="/forgotPaswordStep1">Forget Password?</Link>
        <GoogleLogin
          clientId={clientId}
          buttonText=" Sign in with google"
          onSuccess={responseGoogle}
          onFailure={responseGoogle}
          // cookiePolicy={"single_host_origin"}
          className="l-signin-btn2"
          // render={(renderProps) => (
          //   <img
          //     src={Google}
          //     alt="Google icon"
          //     onClick={renderProps.onClick}
          //   />
          // )}
        ></GoogleLogin>
        <button type="submit" className="LoginBtn">
          Login
          <ToastContainer />
        </button>
      </form>
      <div className="image-div">
        <img src={LoginUser} alt="" />
      </div>
    </div>
  );
};

export default Login;
