import React from "react";
import Editors from "../../Component/Editor/Editors";
import FinalLandings from "../../Component/FinalLanding/FinalLandings";
const FinalPage = () => {
  return (
    <div>
      <Editors />
      <FinalLandings />
    </div>
  );
};

export default FinalPage;
