import React from "react";
import Navbar from "../../Component/Navbar1/Navbar.js";
import SEO from "../../SEO.js";
import SignUp from "../../Component/signup-form/SignUp";
const Sign = () => {
  return (
    <div>
      <SEO
        title={`Sign Up | Writro`}
        og_url={"/signUp"}
        og_type="e-book"
        og_title={`Sign Up  | Writro`}
        og_description={"Writro: Where Every Writer's Journey Begins"}
        og_image="https://writro.com/images/writro.png"
      />
      <Navbar />
      <SignUp />
    </div>
  );
};

export default Sign;
