import React from "react";
import Navbar from "../../Component/Navbar1/Navbar.js";
import Login from "../../Component/Login/Login";
import SEO from "../../SEO.js";
const LoginPage = () => {
  return (
    <div>
      <SEO
        title={`Login | Writro`}
        og_url={"/login"}
        og_type="e-book"
        og_title={`Login | Writro`}
        og_description={"Writro: Where Every Writer's Journey Begins"}
        og_image="https://writro.com/images/writro.png"
      />
      <Navbar />
      <Login />
    </div>
  );
};

export default LoginPage;
