import React from "react";
import Forget from "../../Component/ForgetPaswordFinalStep/Forget";
import Navbar from "../../Component/Navbar1/Navbar";
const ForgetPaswordStep3 = () => {
  return (
    <div>
      <Navbar />
      <Forget />
    </div>
  );
};

export default ForgetPaswordStep3;
