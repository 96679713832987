import React from "react";
import { HomeContent } from "../../Component/HomeContent/HomeContent";
import Navbar from "../../Component/Navbar1/Navbar";
import Footer from "../../Component/Footer/Footer";
import SEO from "../../SEO";
const Homes = () => {
  return (
    <div>
      <SEO
        title={`Writro - Want to publish your book? Write on the go with WRITRO,`}
        og_url={"/"}
        og_type="e-book"
        og_title={`Writro - Want to publish your book? Write on the go with WRITRO,`}
        og_description={"Writro: Where Every Writer's Journey Begins"}
        og_image="https://writro.com/images/writro.png"
      />
      <Navbar />
      <HomeContent />
      <Footer />
    </div>
  );
};

export default Homes;
