import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { changePassword } from "../../Api/Authentication";
import LoginUser from "../../assets/loginSignup/loginpageF.png";
const ChangePasswords = () => {
  const navigate = useNavigate();
  const [current_password, setCurrent_password] = useState("");
  const [new_password, setNew_password] = useState("");
  const [confirm_Password, setConfirm_Password] = useState("");
  const [error, setError] = useState({ open: false, msg: "" });

  const handleSubmit = (e) => {
    e.preventDefault();
    setError({ open: false, msg: "" });
    if (new_password !== confirm_Password) {
      setError({ open: true, msg: "Password does not match " });
      return;
    } else if (new_password.length < 8) {
      setError({ open: true, msg: "Password must shoud be 8 digit" });
    }
    const userData = {
      current_password,
      new_password,
    };
    changePassword(userData)
      .then((res) => {
        if (res?.status === 200) {
          localStorage.setItem("writroToken", res?.data?.token);
          toast("Password Changed succesfull", {
            closeOnClick: false,
            closeButton: false,
            type: toast.TYPE.SUCCESS,
            autoClose: 500,
            position: toast.POSITION.TOP_RIGHT,
            className: "sucesstoaster",
          });
          setTimeout(() => {
            navigate(`/publish`);
          }, 500);
        }
      })
      .catch((e) =>
        toast(e?.response?.data?.msg, {
          closeOnClick: false,
          closeButton: false,
          type: toast.TYPE.ERROR,
          autoClose: 500,
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: false,
          className: "toaster",
        })
      );
  };

  return (
    <div className="loginPage">
      <form className="form-section" onSubmit={handleSubmit}>
        <h1>Change Password</h1>

        <div className="gap1"></div>
        <div className="form-control">
          <label for="password">Current Password</label>
          <input
            type="password"
            name="current_password"
            id="current_password"
            placeholder="Enter Your Current Password"
            onChange={(e) => setCurrent_password(e.target.value)}
          />
        </div>
        <div className="form-control">
          <label for="password">New Password</label>
          <input
            type="password"
            name="new_password"
            id="new_password"
            placeholder="Enter Your New Password"
            onChange={(e) => setNew_password(e.target.value)}
          />
        </div>
        <div className="form-control">
          <label for="password">Confirm Password</label>
          <input
            type="password"
            name="confirm_Password"
            id="confirm_Password"
            placeholder="Enter Confirm Password"
            onChange={(e) => setConfirm_Password(e.target.value)}
          />
        </div>
        {error.open && <p className="error">{error.msg}</p>}
        <button type="submit" className="LoginBtn">
          Change Password
          <ToastContainer />
        </button>
      </form>
      <div className="image-div">
        <img src={LoginUser} alt="" />
      </div>
    </div>
  );
};

export default ChangePasswords;
