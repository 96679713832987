import React, { useState, useEffect } from "react";
import "../../../Component/Shop/Community_Location/CommunityLocation.css";
// import dp from "../../assets/png/banner2.png";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import { getAllbooks } from "../../../Api/Authentication";
// import SkeletonCard from "../../component/Skeleton/SkeletonCard";
import SkeletonCard from "../../Skeleton/SkeletonCard";
import { userCreatePayment } from "../../../Api/Authentication";
import { displayRazorpay } from "../../../Utills/Razzerpay";
const CommunityLocations = () => {
  const history = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isRefresh, setIsRefresh] = useState(false);
  var api_1_data = {};
  var api_2_data = {};
  const handlePayment = async (id) => {
    const data = {
      book_id: id,
    };
    await userCreatePayment(data).then((res) => {
      const response2 = res.data;
      api_2_data = response2;
      setIsRefresh(!isRefresh);
      if (res?.status === 200) {
        displayRazorpay(api_2_data);
      }
    });
  };
  useEffect(() => {
    const loadData = () => {
      getAllbooks().then((response) => {
        if (response?.status === 200) {
          setLoading(true);
          setData(response?.data);
        }
      });
    };
    loadData();
  }, [isRefresh]);
  const Loader = () => {
    return Array(3)
      .fill()
      .map((e, i) => <SkeletonCard key={i} />);
  };

  return (
    <>
      {loading === false ? (
        <div className="SkeletonCard">
          <Loader />
        </div>
      ) : (
        <div className="Community-chapter-main">
          {data?.map((value) => {
            localStorage.setItem("community_slug", value?.slug);
            return (
              <div
                className="Community-chapter"
                onClick={() =>
                  history.push({
                    pathname: `/communitiy-chapter/${value?.slug}`,
                    state: { value: value },
                  })
                }
              >
                <Card className="bookShop_container">
                  <CardMedia
                    image={value?.book?.cover_image}
                    title={value?.book?.title}
                    component="img"
                    height="auto"
                    className="book_shop_image"
                  />
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    className="cardContentName"
                  >
                    {value?.book?.title}
                  </Typography>
                  <Typography
                    gutterBottom
                    variant="p"
                    component="div"
                    className="cardContentName"
                  >
                    by {value?.book?.author_name}
                  </Typography>
                  {value?.amount >= 1 ? (
                    <div className="cardbtn_container">
                      <Typography
                        gutterBottom
                        variant="p"
                        component="div"
                        className="cardContentName"
                      >
                        {value?.amount} Rs
                      </Typography>
                      <Typography
                        gutterBottom
                        variant="p"
                        component="button"
                        className="cardContentbtn"
                        onClick={() => handlePayment(value?.bookId?.id)}
                      >
                        Buy Now
                      </Typography>
                    </div>
                  ) : (
                    <div className="cardbtn_container">
                      <Typography
                        gutterBottom
                        variant="p"
                        component="div"
                        className="cardContentName"
                      >
                        Free
                      </Typography>
                      <Typography
                        gutterBottom
                        variant="p"
                        component="button"
                        className="cardContentbtn"
                        onClick={() => handlePayment(value?.bookId?.id)}
                      >
                        Add Now
                      </Typography>
                    </div>
                  )}
                </Card>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

export default CommunityLocations;
