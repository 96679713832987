import React from "react";
import Navbar from "../../Component/Navbar1/Navbar";
import Forget from "../../Component/ForgetPasword/Forget";
import SEO from "../../SEO";

const ForgetPaswordStep1 = () => {
  return (
    <div>
      <SEO
        title={`Forgot | Writro`}
        og_url={"/forgotPaswordStep1"}
        og_type="e-book"
        og_title={`Forgot  | Writro`}
        og_description={"Writro: Where Every Writer's Journey Begins"}
        og_image="https://writro.com/images/writro.png"
      />
      <Navbar />
      <Forget />
    </div>
  );
};

export default ForgetPaswordStep1;
