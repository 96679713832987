import React from "react";
import Editors from "../../Component/Editor/Editors";
import BooksPreviews from "../../Component/BookPreview/BooksPreviews";
const BookPreview = () => {
  return (
    <div>
      <Editors />
      <BooksPreviews />
    </div>
  );
};

export default BookPreview;
