import React from "react";
import ChangePasswords from "../../Component/ChangePasswords/ChangePasswords";
import Navbar from "../../Component/Navbar1/Navbar";
import SEO from "../../SEO";
const ChangePassword = () => {
  return (
    <div>
      <SEO
        title={`Change Password | Writro`}
        og_url={"/changePassword"}
        og_type="e-book"
        og_title={`Change Password | Writro`}
        og_description={"Writro: Where Every Writer's Journey Begins"}
        og_image="https://writro.com/images/writro.png"
      />
      <Navbar />
      <ChangePasswords />
    </div>
  );
};

export default ChangePassword;
