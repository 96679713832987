import React from "react";
import { useEffect } from "react";
import { createContext } from "react";

export const AuthContext = createContext(null);
function AuthContextProvider(props) {
  const [isAuthed, setIsAuthed] = React.useState(false);

  const [isLoading, setIsLoading] = React.useState(true);

  useEffect(() => {
    const Token = localStorage.getItem("writroToken");

    if (Token) {
      setIsAuthed(true);
      setIsLoading(false);
    }
  }, []);
  return (
    <AuthContext.Provider value={{ isLoading, isAuthed }}>
      {props.children}
    </AuthContext.Provider>
  );
}

export default AuthContextProvider;
