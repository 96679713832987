import React from "react";
import Editors from "../../Component/Editor/Editors";
import BooksInfo from "../../Component/BookInfo/BooksInfo";
const BookInfo = () => {
  return (
    <div>
      <Editors />
      <BooksInfo />
    </div>
  );
};

export default BookInfo;
