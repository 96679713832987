import { paymentFinalStep } from "../Api/Authentication";
export function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}

const handlePaymentProcessing = (data) => {
  paymentFinalStep(data)
    .then((res) => {
      if (res?.data?.status === true) {
        if (data?.status === "SUCCESS") {
          window.location.reload();
        }
      }
    })
    .catch((e) => {});
};
const handleBookingPaymentResponse = (res) => {
  let paymetStatus = {
    status: true,
    order_id: res?.razorpay_order_id,
    payment_id: res?.razorpay_payment_id,
    signature_id: res?.razorpay_signature,
  };
  handlePaymentProcessing(paymetStatus);
};

export async function displayRazorpay(data) {
  const path = window.location.pathname;
  const temp = path.split("/");
  const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");
  if (!res) {
    alert("Razorpay SDK failed to load. Are you online?");
    return;
  }

  const options = {
    key: "rzp_live_GjHtQ6qexhz21c",
    // key:"rzp_live_QtxRph4NV7qHji",
    currency: "INR",
    amount: data?.amount?.toString(),
    order_id: data?.order_id,
    name: `Writro`,
    description: "Keep Reading & Learning",
    image: "http://localhost:1337/logo.svg",
    handler: handleBookingPaymentResponse,
  };
  var rzp1 = new window.Razorpay(options);
  rzp1.on("payment.failed", function (response) {
    let paymetStatus = {
      status: "FAILED",
      description: response.error.description,
      code: response.error.code,
      source: response.error.source,
      reason: response.error.reason,
      order_id: response.error.metadata.order_id,
      payment_id: response.error.metadata.payment_id,
    };
    handlePaymentProcessing(paymetStatus);
  });

  rzp1.open();
}
