import React from "react";
import Skeleton from "@mui/material/Skeleton";
import "./SkeletonCard.scss";
export default function SkeletonCard() {
  return (
    <div className="skeleton-card">
      <div className="big-card">
        <Skeleton variant="rectangular" animation="wave" />
      </div>
      <div className="mini-card">
        <Skeleton variant="rectangular" animation="pulse" />
      </div>
      <div className="mini-card">
        <Skeleton variant="rectangular" animation="pulse" />
      </div>
      <div className="mini-card">
        <Skeleton variant="rectangular" animation="pulse" />
      </div>
      <div className="mini-card">
        <Skeleton variant="rectangular" animation="pulse" />
      </div>
    </div>
  );
}
