import React, { useEffect, useState } from "react";
import "./BookDescription.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import { Carousel } from "react-responsive-carousel";
import { getBooksSlug, allBooks } from "../../Api/Authentication";
import { useParams } from "react-router-dom";
import AllBook from "./AllBook";
import Navbar from "../../Component/Navbar1/Navbar";
import Footer from "../../Component/Footer/Footer";
import Default from "../../images/homePage/default-profile.png";
import SEO from "../../SEO";

const carouselProp = {
  showStatus: false,
  useKeyboardArrows: true,
  infiniteLoop: true,
  autoPlay: false,
  stopOnHover: true,
  emulateTouch: true,
  transitionTime: 400,
  showArrows: true,

  renderArrowPrev: (clickHandler, hasPrev, label) => {
    return (
      <span className="arrow-left" onClick={clickHandler}>
        <span className="icon-keyboard_arrow_left">
          <ArrowBackIosNewRoundedIcon />
          {/* <FontAwesomeIcon icon={faAngleLeft} color="red" /> */}
        </span>
      </span>
    );
  },
  renderArrowNext: (clickHandler, hasNext, label) => {
    return (
      <span className="arrow-right" onClick={clickHandler}>
        <span className="icon-keyboard_arrow_right">
          <ArrowForwardIosRoundedIcon />
          {/* <FontAwesomeIcon icon={faAngleRight} color="red" /> */}
        </span>
      </span>
    );
  },
};

export const ReadDetails = () => {
  const { slug } = useParams();
  const [book, setBook] = useState(() => {
    const storedBook = localStorage.getItem("book");
    return storedBook ? JSON.parse(storedBook) : null;
  });
  const [allBooksData, setAllBooksData] = React.useState([]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    const fetchBookData = async () => {
      try {
        const response = await getBooksSlug(slug);
        const newBook = response.data;
        setBook(newBook);
        allBooks(newBook.writer.slug).then((res) => {
          setAllBooksData(res.data);
        });
        localStorage.setItem("book", JSON.stringify(newBook));
      } catch (error) {
        console.error("Error fetching book data:", error);
      }
    };

    fetchBookData();
  }, [slug]);
  useEffect(() => {
    if (book) {
      const { book_name, discription, slug, thumbnail_image } = book;
      const pageTitle = `Writro Books  | Book Lists | ${book_name}`;
      document.title = pageTitle;
      const metaDescription = document.querySelector(
        'meta[name="description"]'
      );
      if (metaDescription) {
        metaDescription.setAttribute("content", discription);
      }
    }
  }, [book]);

  return (
    <>
      {" "}
      {book && (
        <div>
          <SEO
            title={`Writro Books | Book Lists | ${book?.title}`}
            og_url={`/BookList/${book?.slug}`}
            og_type="article"
            og_title={`Writro Books | Book Lists | ${book?.title}`}
            og_description={book?.discription}
            og_image={book?.thumbnail_image}
          />
          <Navbar />
          <div className="bookDescriptionContainer">
            <div className="bookDescription">
              <div className="bookFirstContent">
                <h1 className="bookName">{book?.title}</h1>
                <h6 className="bookAuthor">Written by {book?.author_name}</h6>
                <p
                  className="bookDiscription"
                  dangerouslySetInnerHTML={{
                    __html: book?.language?.description,
                  }}
                />
              </div>
              <div className="booksecondContent">
                <Carousel showArrows={true} {...carouselProp}>
                  <div className="imageConatiner">
                    <img className="caroselImage" src={book?.cover_image} />
                  </div>
                </Carousel>
              </div>
            </div>
          </div>
          <section className="Main_HomeWrapper Description_wrapper BookDesciption_Wrapper AuthorDescription_Wrapper">
            <div className="container">
              <div className="authorNameContainer">
                <h1 className="authorName">About The Author</h1>
              </div>
              <div className="Description_Content">
                <div className="Description_Left">
                  <figure>
                    <div className="img_Wrp">
                      <img
                        src={book?.writer?.user_profile?.profile_pic || Default}
                        alt="Author profile"
                      />
                    </div>
                  </figure>
                </div>
                <div className="Description_Right">
                  <div className="About_Book">
                    <figcaption>
                      <div className="bookDes_Wrp">
                        <div className="container">
                          <div className="bookDes_Wrp_Content">
                            {/* <h2>About The Author</h2> */}
                            <p>{book?.description}</p>
                          </div>
                        </div>
                      </div>
                    </figcaption>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <AllBook book={book?.book_images} allBooksData={allBooksData} />
          <Footer />
        </div>
      )}
    </>
  );
};
