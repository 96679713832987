import React, { useState } from "react";
import { finalStep } from "../../Api/Authentication";
import { ToastContainer, toast } from "react-toastify";
import LoginUser from "../../assets/loginSignup/loginpageF.png";
import { useNavigate } from "react-router-dom";
const Forget = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [email_otp, setEmail_otp] = useState("");
  const [phone_number_otp, setPhone_number_otp] = useState("");
  const [password, setPassword] = useState("");
  const [phone_number, setPhone_number] = useState("");
  const handleSubmit = (e) => {
    e.preventDefault();

    const userData = {
      email: localStorage.getItem("email"),
      email_otp,
      password,
    };

    finalStep(userData)
      .then((res) => {
        if (res?.status === 200) {
          navigate(`/login`);
        }
      })
      .catch((e) =>
        toast(e?.response?.data?.msg, {
          closeOnClick: false,
          closeButton: false,
          type: toast.TYPE.ERROR,
          autoClose: 1000,
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: false,
          className: "toaster",
        })
      );
  };
  localStorage.setItem("otp", phone_number_otp);
  return (
    <div class="loginPage">
      <form class="form-section" onSubmit={handleSubmit}>
        <h1>Forget Password</h1>

        <div class="gap1"></div>
        <div class="form-control">
          <label for="password">Email</label>
          <input
            type="email"
            name="email"
            id="email"
            placeholder="Enter your email or username"
            value={localStorage.getItem("email")}
            // onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div class="form-control">
          <label for="password">OTP</label>
          <input
            type="text"
            name="text"
            id="text"
            placeholder="Enter Email OTP"
            onChange={(e) => setEmail_otp(e.target.value)}
          />
        </div>
        <div class="form-control">
          <label for="password">Password</label>
          <input
            type="password"
            name="password"
            id="password"
            placeholder="Enter Your New Password"
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <button type="submit" className="LoginBtn">
          Next
          <ToastContainer />
        </button>
      </form>
      <div class="image-div">
        <img src={LoginUser} alt="" />
      </div>
    </div>
  );
};

export default Forget;
