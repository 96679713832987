import React, { useState } from "react";
import Navbar from "../../Component/Navbar1/Navbar";
import Publishs from "../../Component/Publish/Publishs";
import SEO from "../../SEO";
const PublishPages = () => {
  const [step, setStep] = useState(0);
  return (
    <div>
      <SEO
        title={`Publish Page | Writro`}
        og_url={"/publish"}
        og_type="e-book"
        og_title={`Publish Page  | Writro`}
        og_description={"Writro: Where Every Writer's Journey Begins"}
        og_image="https://writro.com/images/writro.png"
      />
      <Navbar />
      <Publishs />
    </div>
  );
};

export default PublishPages;
