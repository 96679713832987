import React from "react";
import Navbar from "../../Component/Navbar1/Navbar";
import Shop from "../../Component/Shop/Shop";
import CommunityLocations from "../../Component/Shop/Community_Location/CommunityLocations";
import SEO from "../../SEO";
const ShopPage = () => {
  return (
    <div>
      <SEO
        title={`Books Shop | Writro`}
        og_url={"/shop"}
        og_type="e-book"
        og_title={`Books Shop  | Writro`}
        og_description={"Writro: Where Every Writer's Journey Begins"}
        og_image="https://writro.com/images/writro.png"
      />
      <Navbar />
      {/* <Shop /> */}
      <CommunityLocations />
    </div>
  );
};

export default ShopPage;
