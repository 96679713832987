import React, { useState, useEffect } from "react";
import "./bookPreview.scss";
import { getPreview, publish } from "../../Api/Authentication";
import { ReactReader } from "react-reader";
import books from "../../assets/Publish/book.epub";
import logo from "../../images/navbar/navbarlogo.png";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { bookIsPaid } from "../../Api/Authentication";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  border: "2px solid #fff",
  boxShadow: 24,
  p: 4,
  opacity: "unset",
  transition: "unset",
  width: "100%",
};
const useStyles = makeStyles((theme) =>
  createStyles({
    smallRadioButton: {
      "& svg": {
        width: "2em",
        height: "2em",
      },
    },
  })
);
const BooksPreviews = () => {
  const navigate = useNavigate();
  const { slug } = useParams();
  const classes = useStyles();
  const [bookData, setBookData] = useState();
  const [chapterData, setChapterData] = useState([]);
  const [openModal, setOpenModal] = React.useState(false);
  const [data, setData] = useState();
  const handleOpen = () => setOpenModal(true);
  const handleModalClose = () => setOpenModal(false);
  useEffect(() => {
    getPreview(slug).then((res) => {
      setBookData(res?.data?.book_detail);
      setChapterData(res?.data?.chapter);
    });
  }, []);

  const handlePublish = () => {
    publish(slug, data)
      .then((res) => {
        if (res?.status === 200 || 201) {
          toast(res?.data?.msg, {
            closeOnClick: false,
            closeButton: false,
            type: toast.TYPE.SUCCESS,
            autoClose: 500,
            position: toast.POSITION.TOP_RIGHT,
            className: "sucesstoaster",
          });
          setTimeout(() => {
            navigate(`/profile`);
          }, 1000);
        }
      })
      .catch((e) =>
        toast(e?.response?.data?.msg, {
          closeOnClick: false,
          closeButton: false,
          type: toast.TYPE.ERROR,
          autoClose: 1000,
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: false,
          className: "toaster",
        })
      );
  };
  const handleMove = (chapterSlug) => {
    navigate(`/editors/${slug}`);
  };
  const handleInput = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  return (
    <div className="preview_container">
      <div className="Preview_text">
        <h1 className="overview">Overview</h1>
      </div>
      <div className="preview_white_banner">
        <img
          src={bookData?.cover_image}
          alt="cover photo"
          className="coverImage"
        />
      </div>
      <div className="preview_white_banner_2">
        <h1 className="book_title">{bookData?.title}</h1>
        <h6 className="by">By</h6>
        <h6 className="book_author">{bookData?.author_name}</h6>
        <div className="border"></div>
        <img src={logo} alt="" className="image_logo" />
      </div>
      <div className="preview_white_banner">
        <div>
          <h1 className="chapterContent_head">Table of Contents</h1>
        </div>
        <h1>CONTENTS</h1>
        {chapterData?.map((value) => {
          return <h4 className="chapterContent">{value?.title}</h4>;
        })}
      </div>
      {chapterData?.map((value) => {
        return (
          <div className="preview_white_banner">
            <div
              onClick={() => handleMove(value?.slug)}
              style={{ cursor: "pointer" }}
            >
              <h4 className="chapterContent">{value?.title}</h4>
              <p
                className="chapter_text"
                dangerouslySetInnerHTML={{
                  __html: value?.content,
                }}
              ></p>
            </div>
          </div>
        );
      })}
      <div className="preview_white_banner">
        <img
          src={bookData?.back_image}
          alt="back cover"
          className="coverImage"
        />
      </div>
      <div className="Profile_btn">
        <button
          className="Profile_btn_1"
          onClick={handleOpen}
          // onClick={handlePublish}
        >
          Publish
          <ToastContainer />
        </button>
      </div>
      <div className="fixed_btn">
        <button
          className="Profile_btn_1"
          onClick={handleOpen}
          // onClick={handlePublish}
        >
          Publish
          <ToastContainer />
        </button>
      </div>
      <Modal
        open={openModal}
        onClose={handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h3"
            component="h2"
            className="modal_container"
          >
            Select your publish types
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="female"
                name="book_type"
                className="radio_btn_container"
                onChange={handleInput}
              >
                <FormControlLabel
                  value="public"
                  control={<Radio />}
                  label="Free"
                  className={classes.smallRadioButton}
                />
                <FormControlLabel
                  value="paid"
                  control={<Radio />}
                  label="Paid"
                  className={classes.smallRadioButton}
                />
              </RadioGroup>
            </FormControl>
          </Typography>
          {data?.book_type === "paid" ? (
            <FormControl fullWidth sx={{ m: 2 }} variant="standard">
              <InputLabel
                htmlFor="standard-adornment-amount"
                style={{ fontSize: "2rem" }}
              >
                Amount
              </InputLabel>
              <Input
                id="standard-adornment-amount"
                type="number"
                name="amount"
                onChange={handleInput}
                startAdornment={
                  <InputAdornment position="start">
                    <CurrencyRupeeIcon />
                  </InputAdornment>
                }
                style={{ fontSize: "2rem" }}
              />
            </FormControl>
          ) : (
            ""
          )}

          <div className="btn_modal_container">
            <Button
              variant="contained"
              onClick={handleModalClose}
              style={{
                borderRadius: 5,
                backgroundColor: "#DC3545",
                fontSize: "14px",
                margin: "0 5%",
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              // onClick={deleteChapter}
              style={{
                borderRadius: 5,
                backgroundColor: "#F89920",
                fontSize: "14px",
              }}
              onClick={handlePublish}
            >
              Publish
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default BooksPreviews;
