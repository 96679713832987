import React, { useState } from "react";
import Navbar from "../../Component/Navbar1/Navbar";
import Editors from "../../Component/Editor/Editors";
import PublishStep4 from "../../Component/Publish/PublishStep4";
const PublishPages = () => {
  const [imageURLS, setImageURLs] = useState();
  const [img, setImg] = useState();
  const [isRefresh, setIsRefresh] = useState(false);
  return (
    <div>
      <Editors
        setImageURLs={setImageURLs}
        imageURLS={imageURLS}
        setIsRefresh={setIsRefresh}
        isRefresh={isRefresh}
      />
      <PublishStep4
        setImageURLs={setImageURLs}
        imageURLS={imageURLS}
        setIsRefresh={setIsRefresh}
        isRefresh={isRefresh}
      />
    </div>
  );
};

export default PublishPages;
